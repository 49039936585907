import React from 'react'
import clearerSkinBanner from "./../../../../../Images/Blog/Clearer Skin, Happier You Acne Cure Facial Secrets/Banner.png";

const ClearerSkinFacialImg = () => {
  return (
    <div className="hairTrimImg">
    <img src={clearerSkinBanner} alt="clearerSkinBanner" loading="lazy" />
  </div>
  )
}

export default ClearerSkinFacialImg
